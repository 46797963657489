import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Hero from "../components/hero";
import Seo from "../components/seo";
import Why from "../components/why";
import TextImage from "../components/text-image";
import NewsletterSection from "../components/newsletter-section";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Fundacja Enjoy The Ride" mdxType="Seo" />
    <Hero mdxType="Hero" />
    <Why mdxType="Why" />
    <TextImage image="illustrations/fire.svg" mdxType="TextImage">
      <h2>{`Być może nikt dotychczas nie powiedział Ci, jak (i że warto!) zadbać o swoją głowę.`}</h2>
      <p>{`Przez to nawet pozornie drobne problemy zalewają nas jak fala.`}</p>
      <p>{`A tymczasem...`}</p>
      <ul>
        <li parentName="ul">{`masz prawo do odczuwania i ekspresji emocji niezależnie od płci (możesz płakać!);`}</li>
        <li parentName="ul">{`żaden problem nie jest „głupi”, „dziwny” czy „za mało ważny, by się nim zająć”;`}</li>
        <li parentName="ul">{`możesz nie życzyć sobie, by ktokolwiek oceniał Ciebie lub Twoją sytuację!`}</li>
      </ul>
      <p>{`Czasami trudno oprzeć się wrażeniu, że już nigdy nie będzie lepiej...`}</p>
    </TextImage>
    <div className="pink-blob">
      <TextImage alternative image="illustrations/boxes.svg" mdxType="TextImage">
        <h2>{`Ale... może być lepiej!`}</h2>
        <p>{`O ile wiemy, jak poradzić sobie z trudną sytuacją. Jak zaprzyjaźnić się ze swoimi emocjami. Zrozumieć, skąd się one biorą i czemu służą. Oraz że nie zawsze musi być różowo, kolorowo i wesoło.`}</p>
      </TextImage>
    </div>
    <TextImage image="illustrations/support.svg" mdxType="TextImage">
      <h2>{`W Fundacji Enjoy The Ride chcemy pokazać Ci, `}<br />{` co dzieje się w środku Twojej głowy.`}</h2>
      <p>{`Dlaczego czujesz się tak, a nie inaczej. Czemu służą "gorsze" dni. Jak możesz sobie radzić z trudnymi sytuacjami i... jak dbać o siebie, by coraz częściej patrzeć na świat z uśmiechem i spokojnym umysłem!`}</p>
    </TextImage>
    <NewsletterSection mdxType="NewsletterSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      